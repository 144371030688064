// HighLevelTab.tsx
import React, { ChangeEvent, useState, useEffect } from 'react';
import { Input, Select, Tooltip, Button, Space, Modal, Spin, Checkbox, Tag } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons'
import axios, { AxiosError, AxiosResponse } from 'axios';
import { User } from "firebase/auth";
import { getFirestore, doc, updateDoc, Timestamp, getDoc, setDoc  } from "firebase/firestore"; 
import './GeneratingGifStyle.css';
import { SharedState, UsersSharedState, TabProps, CustomAxiosError } from './types';
 
const { TextArea } = Input;
const { Option } = Select;



const HighLevelTab: React.FC<TabProps> = ({ sharedState, setSharedState, isAuth, user, usersSharedState, setUsersSharedState }) => {
  // Define your options here
  const gpt_models = ['Chat-GPT', 'Claude'];
  const prose_tenses = ["Past Tense", "Present Tense", "Future Tense"]; 
  const prose_povs = ["First Person (Single PoV)", "First Person (Multiple PoV)", "Third Person", "Second Person"]; 
  const num_chapters = Array.from({ length: 36 }, (_, i) => i + 5); // 2-75
  const genres = ['Adventure', 'Mystery', 'Sci-Fi', 'Fantasy', 'Romance', 'Historical Romance','Clean Romance','Christian Romance','Thriller', 'Drama', 'Horror', 'Historical Fiction', 'Comedy', 'Graphic Novel', 'Realistic Fiction', 'Action', 'Detective', 'Dystopian', 'Steampunk', 'Gothic', 'Crime', 'Young Adult', 'Children', 'Paranormal', 'Suspense', 'Political', 'Spiritual', 'Satire', 'War', 'Western','Urban Fantasy','Epic Fantasy','High Fantasy','Dark Fantasy','Science Fantasy','Romantic Comedy','Classic Literature','Gay Erotica', 'Gay Paranormal Erotica', 'Gay Paranormal Shifter Erotica','Steamy Erotica','Steamy Romance','Steamy Romantic Comedy', 'Steamy Sports Romance', 'Steamy Biker Romance'];
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [infoTitle, setInfoTitle] = useState("");
  const [infoContent, setInfoContent] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalVisible, setErrorModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isConfirmTropesModalVisible, setIsConfirmTropesModalVisible] = useState(false);
  const [isConfirmWritingStyleModalVisible, setIsConfirmWritingStyleModalVisible] = useState(false);
  const [isConfirmOverviewModalVisible, setIsConfirmOverviewModalVisible] = useState(false);
  const [shouldSkipTropesConfirm, setShouldSkipTropesConfirm] = useState(false);
  const [shouldSkipWritingStyleConfirm, setShouldSkipWritingStyleConfirm] = useState(false);
  const [shouldSkipOverviewConfirm, setShouldSkipOverviewConfirm] = useState(false);
  

  const [costState, setCostState] = useState<Record<string, any>>({}); 

  const [apiModel, setApiModel] = useState("Claude")
  const [bookTitle, setBookTitle] = useState("")
  const [bookAuthor, setBookAuthor] = useState("")
  const [bookSeriesName, setBookSeriesName] = useState("")
  const [bookSeriesNbr, setBookSeriesNbr] = useState(0)
  const [bookTense, setBookTense] = useState("Past Tense")
  const [bookPOV, setBookPOV] = useState("First Person (Single PoV)")
  const [bookNumChapters, setBookNumChapters] = useState(6)
  const [bookGenre, setBookGenre] = useState("")
  const [bookWritingStyle, setBookWritingStyle] = useState("")
  const [bookTropes, setBookTropes] = useState("")
  const [bookBasics, setBookBasics] = useState("")
  
  const [overviewComments, setOverviewComments] = useState("")
  const [filteredGenres, setFilteredGenres] = useState<string[]>([]);
  const [tropeSuggestions, setTropeSuggestions] = useState("");
  const [isTropeSuggestionsModalVisible, setIsTropeSuggestionsModalVisible] = useState(false);
  
const filterGenres = (genres: string[], userID: string) => {
  if (userID === 'YSTNiilkPbUZpYdMXZaA1AO3uex2') {
    return genres;
  }
  return genres.filter(genre => 
    !['Steamy Romance', 'Steamy Erotica', 'Gay Erotica', 'Gay Paranormal Erotica', 'Gay Paranormal Shifter Erotica','Steamy Erotica','Steamy Romance', 'Steamy Sports Romance', 'Steamy Biker Romance', 'Steamy Romantic Comedy'].includes(genre)
  );
};

useEffect(() => {
  setFilteredGenres(filterGenres(genres, usersSharedState.userID));
}, [usersSharedState.userID]);

  const db = getFirestore();


  ///Load Book data
  useEffect(() => {
    const fetchData = async () => {
      if (!sharedState.bookguid) return;  // Exit if no bookguid is available

      setIsLoading(true);
      try {
        const response = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/api/books/highlevel/${sharedState.bookguid}`);
        const data = response.data;
        
          setBookTitle(data.title);
		  setBookAuthor(data.author);
		  setBookSeriesName(data.seriesName);
		  setBookSeriesNbr(data.seriesNumber);
		  setApiModel(data.model);
          setBookTense(data.tense);
          setBookPOV(data.pov);
          setBookNumChapters(data.numChapters);
          setBookGenre(data.genre);
          setBookWritingStyle(data.writingStyle);
          setBookTropes(data.tropes);
		  setBookBasics(data.overview);
		  
      } catch (error: any) {
        console.error("Failed to fetch book details: ", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [sharedState.bookguid]); 

  
const handleBlurSave = async (field: string) => {
  // Create a mapping of field names to their corresponding state values
  const fieldToStateMap: Record<string, any> = {
    title: bookTitle,
    author: bookAuthor,
    seriesName: bookSeriesName,
    seriesNumber: bookSeriesNbr,
    model: apiModel,
    tense: bookTense,
    pov: bookPOV,
    numChapters: bookNumChapters,
    genre: bookGenre,
    writingStyle: bookWritingStyle,
    tropes: bookTropes
  };

  const value = fieldToStateMap[field];
  if (sharedState.bookguid && isAuth && user) {
    try {
      const bookRef = doc(db, 'books', sharedState.bookguid);
      setIsLoading2(true);

      // Only update the field that was changed
      await updateDoc(bookRef, { [field]: value, lastModifiedDate: Timestamp.now() });
    } catch (error: any) {
      console.error("Error updating document:", error);
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("An unexpected error occurred");
      }
    } finally {
      setIsLoading2(false);
    }
  }
};

  
  useEffect(() => {
    const fetchGenerationCosts = async () => {
      setIsLoading2(true);
      try {
        const generationCostsResponse = await axios.get('https://askpoeai-nswdzfky7q-uc.a.run.app/get_generation_cost/');
        const costsData: Record<string, any> = generationCostsResponse.data.creditCosts;
        const generationTypes = [
          'BasicPlot', 'Tropes', 'WritingStyle', 'MainCharacters', 
          'SupportingCharacters', 'Locations', 'Outline', 
          'Title', 'Blurb', 'Novel', 'ChapterSummary','CharacterName','CharacterDetails',
          'LocationName','LocationDetails'
        ];

        let newState: Record<string, any> = {}; 
        for (let genType of generationTypes) {
          const key = `${genType}_${apiModel}`;
          newState[`gen${genType}Cost`] = costsData[key];
        }

        // Updating the new costState with the fetched costs
        setCostState(newState);
        
		console.log("Cost State genTropesCost: "+costState.genTropesCost);
		
      } catch (error) {
        console.error("Failed to fetch generation costs:", error);
      } finally {
        setIsLoading2(false);
      }
    };

    if (apiModel) {  // Ensuring model is available before fetching
      fetchGenerationCosts();
    }
  }, [sharedState.bookguid, apiModel]);


  const handleInfoModal = (visible: boolean, source: string) => {
	if (source === 'GPTModel')
		{
		setInfoTitle("GPT API Model Information");
		setInfoContent(`gpt-4: The latest and most powerful model. Excellent language understanding and generation, but is slower and costs more than gpt-3.5. 
	
	gpt-3.5: A balance between performance and cost. Writing will be lower quality than GPT-4 and will likely require more human editing. Has a maximum of approximately 20-25 chapters.`);
		}
		else if (source === 'Tropes')
		{
		setInfoTitle("Tropes Information");
		setInfoContent(`ScribeShadow can generate tropes based on the Genre selected, or you can add your own. 
		
		The tropes will be used when generating the Plot and other objects, so make sure you are happy with them before continuing.`);
		}
		else if (source === 'WritingStyle')
		{
		setInfoTitle("Writing Style Information");
		setInfoContent(`ScribeShadow can generate a writing style for it to write in based on the Genre selected. You can also add your own description of the writing style you want ScribeShadow to use.
		
		ScribeShadow will use this writing style when composing your novel.`);
		}	
		else if (source === 'BasicPlot')
		{
		setInfoTitle("Basic Plot Information");
		setInfoContent(`ScribeShadow can generate a basic plot based on the Genre and Tropes in the High Level tab. You can also write your own plot that you want ScribeShadow to use.
		
		Any character names or locations included in the Basic Plot will be used when ScribeShadow generates characters or locations, so make sure you are happy with them before continuing.
		
		ScribeShadow will use the Basic Plot when generating Characters, Locations, and your Outline.`);
		}	
		else if (source === 'Title')
		{
		setInfoTitle("Title Information");
		setInfoContent(`This is the title of your novel. It can be changed at any time.
	ScribeShadow can also generate a title for you in the Metadata tab once the Basic Plot is completed.`);
		}
		else if (source === 'NumChapters')
		{
		setInfoTitle("Number of Chapters Information");
		setInfoContent(`This is how long your want your novel to be. ScribeShadow has an overall minimum of 6 chapters, and a maximum of 75 chapters. However your maximum chapters will depend on your plan.
		
		Please note that increasing the number of chapters will increase the credit costs of outline and novel generations.`);
		}		
		else if (source === 'Author')
		{
		setInfoTitle("Author Information");
		setInfoContent(`The name, or pen name, you want to use for this book.`);
		}		
		else if (source === 'Series')
		{
		setInfoTitle("Series Information");
		setInfoContent(`The series name and number for this book. Series information is optional. If a series name is provided, you do not have to provide a series number if the books do not have to be read in order.`);
		}	
		else if (source === 'Overview')
		{
		setInfoTitle("Overview Information");
		setInfoContent(`ScribeShadow can generate a basic overview of your novel. This is a very high level plot that will be further refined in future steps. ScribeShadow will use your genre and tropes (if any) to generate your overview.`);
		}			
		setIsInfoModalVisible(visible);
	};

  let infoModalContent = (
    <p>
      {infoContent.split('\n').map((line, i) => (
        <span key={i}>
          {line}
          <br />
        </span>
      ))}
    </p>
  );

  const handleGenreChange = (value: string) => {
    setBookGenre(value);
  };

const handleModelChange = (value: string) => {
  setApiModel(value);
};



  const handleTenseChange = (value: string) => {
    setBookTense(value);
  };
  
  const handlePoVChange = (value: string) => {
    setBookPOV(value);
  };
  
  const handleNumChaptersChange = (value: number) => {
    setBookNumChapters(value);
  };
  
  const handleErrorModal = (visible: boolean) => {
    setErrorModalVisible(visible);
  };



// Confirmation handlers
const handleConfirmTropesModal = async (confirm: boolean) => {
  setIsConfirmTropesModalVisible(false);
  if (confirm) {
    handleGenerateTropes();
  }
};

const handleConfirmOverviewModal = async (confirm: boolean) => {
  setIsConfirmOverviewModalVisible(false);
  if (confirm) {
    handleGenerateOverview();
  }
};

const handleConfirmWritingStyleModal = async (confirm: boolean) => {
  setIsConfirmWritingStyleModalVisible(false);
  if (confirm) {
    handleGenerateWritingStyle();
  }
};


const handleGenerateTropesClick = async () => {
  setIsTropeSuggestionsModalVisible(true);
};

const handleGenerateOverviewClick = async () => {
    setIsConfirmOverviewModalVisible(true);
};

const handleGenerateWritingStyleClick = async () => {
  if (bookWritingStyle.trim() !== '' && !shouldSkipWritingStyleConfirm) {
    setIsConfirmWritingStyleModalVisible(true);
  } else {
    handleGenerateWritingStyle();
  }
};

const handleGenerateTropesWithSuggestions = async () => {
  setIsTropeSuggestionsModalVisible(false);
  if (bookTropes.trim() !== '' && !shouldSkipTropesConfirm) {
    setIsConfirmTropesModalVisible(true);
  } else {
    handleGenerateTropes();
  }
};
  
const handleGenerateTropes = async () => {
  if (!costState.genTropesCost) {
    console.error('Cost of generating tropes is not defined.');
    return;
  }  

  setIsLoading(true);
  
  try {
    const response = await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/generate_tropes/', { 
      apimodel: apiModel, 
      genre: bookGenre,
      userID: usersSharedState.userID,
	  overview: tropeSuggestions,
    });
    
    setBookTropes(response.data.tropes);

    if (sharedState.bookguid && isAuth && user) {
      try {
        const bookRef = doc(db, 'books', sharedState.bookguid);
        // Update only the 'tropes' and 'lastModifiedDate' fields
        await updateDoc(bookRef, { 
          tropes: response.data.tropes, 
          lastModifiedDate: Timestamp.now() 
        });
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    }
  } catch (err) {
    const axiosErr = err as AxiosError;
    if (axiosErr.response && axiosErr.response.data && typeof (axiosErr.response.data as any).detail === 'string') {
      setErrorMessage((axiosErr.response.data as any).detail);
    } else {
      setErrorMessage('An error occurred while generating the tropes.');
    }
    handleErrorModal(true);
  } finally {
    setIsLoading(false);
  }
};

const handleGenerateOverview = async () => {
  if (!costState.genTropesCost) {
    console.error('Cost of generating overview is not defined.');
    return;
  }  

  setIsLoading(true);
  
  try {
    const response = await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/generate_overview/', { 
      apimodel: apiModel, 
      genre: bookGenre,
      userID: usersSharedState.userID,
	  tropes: bookTropes,
	  bookGuid: sharedState.bookguid,
	  overviewComments: overviewComments
    });
    
    setBookBasics(response.data.overview);

    if (sharedState.bookguid && isAuth && user) {
      try {
        const bookRef = doc(db, 'books', sharedState.bookguid);
        // Update only the 'tropes' and 'lastModifiedDate' fields
        await updateDoc(bookRef, { 
          overview: response.data.overview, 
          lastModifiedDate: Timestamp.now() 
        });
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    }
  } catch (err) {
    const axiosErr = err as AxiosError;
    if (axiosErr.response && axiosErr.response.data && typeof (axiosErr.response.data as any).detail === 'string') {
      setErrorMessage((axiosErr.response.data as any).detail);
    } else {
      setErrorMessage('An error occurred while generating the tropes.');
    }
    handleErrorModal(true);
  } finally {
    setIsLoading(false);
  }
};

const handleGenerateWritingStyle = async () => {
  if (!costState.genWritingStyleCost) {
    console.error('Cost of generating writing style is not defined.');
    return;
  }

  setIsLoading(true);
  
  try {
    const response = await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/generate_writingstyle/', {
      apimodel: apiModel, 
      genre: bookGenre,
      userID: usersSharedState.userID
    });
    
    setBookWritingStyle(response.data.writingStyle);

    if (sharedState.bookguid && isAuth && user) {
      try {
        const bookRef = doc(db, 'books', sharedState.bookguid);
        // Update only the 'writingStyle' and 'lastModifiedDate' fields
        await updateDoc(bookRef, { 
          writingStyle: response.data.writingStyle, 
          lastModifiedDate: Timestamp.now() 
        });
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    }
  } catch (err) {
    const axiosErr = err as AxiosError;
    if (axiosErr.response && axiosErr.response.data && typeof (axiosErr.response.data as any).detail === 'string') {
      setErrorMessage((axiosErr.response.data as any).detail);
    } else {
      setErrorMessage('An error occurred while generating the writing style.');
    }
    handleErrorModal(true);
  } finally {
    setIsLoading(false);
  }
};

  
  return (
  <Spin spinning={isLoading2}>
    <div>
        <div style={{ marginBottom: '20px' }}>
          {!sharedState.bookguid && isAuth && <Tooltip title="Please start or open a book in the Book Management tab."><p><b>Please start or open a book in the Book Management tab.<br/>Fields will become available once a book is in progress.</b></p></Tooltip>}
		  {!isAuth && <Tooltip title="Please click Get Started."><p><b>Click Get Started to sign up and start generating books!</b></p></Tooltip>}
          <Space>
            <p style={{ fontWeight: 'bold' }}>Title:</p>
            <Tooltip title="Click for more information.">
              <InfoCircleOutlined
                style={{ color: 'blue', cursor: 'pointer' }}
                onClick={() => {handleInfoModal(true, 'Title')                 
                }}
              />
            </Tooltip>
          </Space>
          <Input
            value={bookTitle}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setBookTitle(e.target.value)}
            onBlur={() => handleBlurSave('title')}
            disabled={!isAuth || !sharedState.bookguid}
          />

        </div>
<div style={{ marginBottom: '20px' }}>
  <Space>
    <p style={{ fontWeight: 'bold' }}>Author:</p>
    <Tooltip title="Click for more information.">
      <InfoCircleOutlined
        style={{ color: 'blue', cursor: 'pointer' }}
        onClick={() => {
          handleInfoModal(true, 'Author');
        }}
      />
    </Tooltip>
  </Space>
  <Input
    value={bookAuthor}
    onChange={(e: ChangeEvent<HTMLInputElement>) =>
      setBookAuthor(e.target.value) }
    onBlur={() => handleBlurSave('author')}
    disabled={!isAuth || !sharedState.bookguid}
  />
</div>

<div style={{ marginBottom: '20px' }}>
  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0px' }}>
    <p style={{ fontWeight: 'bold', marginRight: '10px', marginBottom: '0' }}>Series Information:</p>
    <Tooltip title="Click for more information.">
      <InfoCircleOutlined
        style={{ color: 'blue', cursor: 'pointer' }}
        onClick={() => {
          handleInfoModal(true, 'Series');
        }}
      />
    </Tooltip>
  </div>

  <div style={{width: '580px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <div style={{ flex: 1, marginRight: '10px' }}>
      <p style={{ marginBottom: '5px' }}>Series Name:</p>
      <Input
          value={bookSeriesName}
          onChange={(e: ChangeEvent<HTMLInputElement>) => 
              setBookSeriesName(e.target.value)
          }
          onBlur={() => handleBlurSave('seriesName')}
          disabled={!isAuth || !sharedState.bookguid}
      />
    </div>

    <div style={{ width: '180px', marginRight: '10px' }}>
      <p style={{ marginBottom: '5px' }}>Series Number:</p>
      <Input
          type="number"
          value={bookSeriesNbr}
          onChange={(e: ChangeEvent<HTMLInputElement>) => 
              setBookSeriesNbr(Number(e.target.value))
          }
          onBlur={() => handleBlurSave('seriesNumber')}
          disabled={!isAuth || !sharedState.bookguid}
      />
    </div>
  </div>
</div>


    <div style={{ marginBottom: '20px' }}>
      <Space>
        <p style={{ fontWeight: 'bold' }}>API Model:</p>
        <Tooltip title="Click for more information">
          <InfoCircleOutlined
            style={{ color: 'blue', cursor: 'pointer' }}
            onClick={() => handleInfoModal(true, 'GPTModel')}
          />
        </Tooltip>
      </Space>
<Select 
    value={apiModel} 
    onChange={handleModelChange} 
    onBlur={() => handleBlurSave('model')} 
    style={{ width: '100%' }} 
    disabled={!sharedState.bookguid}
>
    {gpt_models.map((model) => (
        <Option 
            value={model} 
            
        >
           
        </Option>
    ))}
</Select>   
    </div>

      <div style={{ marginBottom: '20px' }}>
        <p style={{ fontWeight: 'bold' }}>Genre:</p>
<Select 
  value={bookGenre} 
  onChange={handleGenreChange} 
  onBlur={() => handleBlurSave('genre')} 
  style={{ width: '100%' }} 
  disabled={!isAuth || !sharedState.bookguid}
>
  {filteredGenres.map((genre) => (
    <Option key={genre} value={genre} disabled={!isAuth || !sharedState.bookguid}>
      {genre}
    </Option>
  ))}
</Select>
      </div>      
      
      <div style={{ marginBottom: '20px' }}>
        <p style={{ fontWeight: 'bold' }}>Tense:</p>
        <Select value={bookTense} onChange={handleTenseChange} onBlur={() => handleBlurSave('tense')} style={{ width: '100%' }} disabled={!isAuth || !sharedState.bookguid}>
          {prose_tenses.map((tense) => (
            <Option value={tense} disabled={!isAuth || !sharedState.bookguid}>{tense}</Option>
          ))}
        </Select>
      </div>
  
      <div style={{ marginBottom: '20px' }}>
        <p style={{ fontWeight: 'bold' }}>Point of View:</p>
        <Select value={bookPOV} onChange={handlePoVChange} onBlur={() => handleBlurSave('pov')} style={{ width: '100%' }} disabled={!isAuth || !sharedState.bookguid}>
          {prose_povs.map((pov) => (
            <Option value={pov} disabled={!isAuth || !sharedState.bookguid}>{pov}</Option>
          ))}
        </Select>
      </div>
  
      <div style={{ marginBottom: '20px' }}>
          <Space>
            <p style={{ fontWeight: 'bold' }}>Number of Chapters:</p>
            <Tooltip title="Click for more information.">
              <InfoCircleOutlined
                style={{ color: 'blue', cursor: 'pointer' }}
                onClick={() => {handleInfoModal(true, 'NumChapters')                 
                }}
              />
            </Tooltip>
          </Space>
<Select value={bookNumChapters} onChange={handleNumChaptersChange} onBlur={() => handleBlurSave('numChapters')} style={{ width: '100%' }} disabled={!isAuth || !sharedState.bookguid}>
  {num_chapters.map((num: number) => (
    <Option value={num} disabled={!isAuth || !sharedState.bookguid}>
      {num}
    </Option>
  ))}
</Select>

      </div>

{/*
      <div style={{ marginBottom: '20px' }}>
        <Space>
          <p style={{ fontWeight: 'bold' }}>Overview:</p>
          <Tooltip title="Click for more information">
            <InfoCircleOutlined
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={() => handleInfoModal(true, 'Overview')}
            />
          </Tooltip>
        </Space>
        <TextArea rows={6} value={bookBasics} onChange={e => setBookBasics(e.target.value)} onBlur={() => handleBlurSave('overview')} disabled={!isAuth || !sharedState.bookguid}/>
        
<div style={{ marginTop: '10px' }}>
  <div style={{ display: 'inline-block' }}>
    {isAuth ? (
      <Button onClick={handleGenerateOverviewClick} disabled={!isAuth || !sharedState.bookguid}>Generate Overview</Button>
    ) : (
      <Tooltip title="Please click 'Get Started' to use this feature.">
        <Button disabled>Generate Overview</Button>
      </Tooltip>
    )}
    {costState.genTropesCost !== null && (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
        <Tag color="blue" style={{ fontWeight: 'bold' }}>
          {costState.genTropesCost} {costState.genTropesCost > 1 ? 'Credits' : 'Credit'}
        </Tag>
      </div>
    )}
  </div>
</div>

      </div>
*/}
	 	  
      <div style={{ marginBottom: '20px' }}>
        <Space>
          <p style={{ fontWeight: 'bold' }}>Tropes:</p>
          <Tooltip title="Click for more information">
            <InfoCircleOutlined
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={() => handleInfoModal(true, 'Tropes')}
            />
          </Tooltip>
        </Space>
        <TextArea rows={6} value={bookTropes} onChange={e => setBookTropes(e.target.value)} onBlur={() => handleBlurSave('tropes')} disabled={!isAuth || !sharedState.bookguid}/>
        
<div style={{ marginTop: '10px' }}>
  <div style={{ display: 'inline-block' }}>
    {isAuth ? (
      <Button onClick={handleGenerateTropesClick} disabled={!isAuth || !sharedState.bookguid}>Generate Tropes</Button>
    ) : (
      <Tooltip title="Please click 'Get Started' to use this feature.">
        <Button disabled>Generate Tropes</Button>
      </Tooltip>
    )}
    {costState.genTropesCost !== null && (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
        <Tag color="blue" style={{ fontWeight: 'bold' }}>
          {costState.genTropesCost} {costState.genTropesCost > 1 ? 'Credits' : 'Credit'}
        </Tag>
      </div>
    )}
  </div>
</div>

      </div>

      <div style={{ marginBottom: '20px' }}>
        <Space>
          <p style={{ fontWeight: 'bold' }}>Writing Style:</p>
          <Tooltip title="Click for more information">
            <InfoCircleOutlined
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={() => handleInfoModal(true, 'WritingStyle')}
            />
          </Tooltip>
        </Space>
        <TextArea rows={6} value={bookWritingStyle} onChange={e => setBookWritingStyle(e.target.value)} onBlur={() => handleBlurSave('writingStyle')}  disabled={!isAuth || !sharedState.bookguid}/>
        
<div style={{ marginTop: '10px' }}>
  <div style={{ display: 'inline-block' }}>
    {isAuth ? (
      <Button onClick={handleGenerateWritingStyleClick} disabled={!isAuth || !sharedState.bookguid}>Generate Writing Style</Button>
    ) : (
      <Tooltip title="Please click 'Get Started' to use this feature.">
        <Button disabled>Generate Writing Style</Button>
      </Tooltip>
    )}
    {costState.genWritingStyleCost !== null && (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
        <Tag color="blue" style={{ fontWeight: 'bold' }}>
          {costState.genWritingStyleCost} {costState.genWritingStyleCost > 1 ? 'Credits' : 'Credit'}
        </Tag>
      </div>
    )}
  </div>
</div>


      </div>

      <Modal 
        title={infoTitle}
        visible={isInfoModalVisible}
        onOk={() => handleInfoModal(false, "")}
        onCancel={() => handleInfoModal(false, "")}
        footer={
          <div style={{ textAlign: 'center' }}>
            <Button key="submit" type="primary" onClick={() => handleInfoModal(false, "")}>
              OK
            </Button>
          </div>
        }
      >
        {infoModalContent}
      </Modal>
	  
	  <Modal 
	  title="Error"
	  visible={isErrorModalVisible}
	  onOk={() => setErrorModalVisible(false)}
	  onCancel={() => setErrorModalVisible(false)}
	  footer={
	 	 <div style={{ textAlign: 'center' }}>
	 	 <Button key="submit" type="primary" onClick={() => setErrorModalVisible(false)}>
	 		 OK
	 	 </Button>
	 	 </div>
	  }
	  >
	  <p>{errorMessage}</p>
	  </Modal>
	  
	  <Modal
	  title={null}
	  visible={isLoading}
	  footer={null}
	  centered
	  closable={false}
	  maskClosable={false}
	  style={{ 
	 	 textAlign: 'center', 
	 	 background: 'transparent', 
	 	 boxShadow: 'none',
	 	 width: 'auto'
	  }}
	  >
	   <img src="generating.gif" alt="Generating..." className="responsive-gif" />
	  </Modal>

	  <Modal 
	  title="Are you sure you want to regenerate your Tropes?"
	  visible={isConfirmTropesModalVisible}
	  onOk={() => handleConfirmTropesModal(true)}
	  onCancel={() => handleConfirmTropesModal(false)}
	  >
	  <p>If you proceed, the existing Tropes text will be deleted and replaced. <br/>
	  
	  Regenerating Tropes will NOT change any other text fields already generated.<br/><br/>
	  
	  Do you want to continue?</p>
	  <Checkbox onChange={(e) => setShouldSkipTropesConfirm(e.target.checked)}>Don't show this again for this session</Checkbox>
	  </Modal>
	  
	  <Modal 
	  title="Are you sure you want to regenerate your Writing Style?"
	  visible={isConfirmWritingStyleModalVisible}
	  onOk={() => handleConfirmWritingStyleModal(true)}
	  onCancel={() => handleConfirmWritingStyleModal(false)}
	  >
	  <p>If you proceed, the existing Writing Style text will be deleted and replaced. <br/>
	    
	  Regenerating Writing Style will NOT change any other text fields already generated.<br/><br/>
	  
	  Do you want to continue?</p>
	  <Checkbox onChange={(e) => setShouldSkipWritingStyleConfirm(e.target.checked)}>Don't show this again for this session</Checkbox>
	  </Modal>

	  <Modal 
	  title="Generate Overview"
	  visible={isConfirmOverviewModalVisible}
	  onOk={() => handleConfirmOverviewModal(true)}
	  onCancel={() => handleConfirmOverviewModal(false)}
	  >
	  <p>Is there anything you want ScribeShadow to consider while generating your overview? You can specify things like a specific setting, characters, or other details you want included.</p>
	  <Input.TextArea 
	 	 rows={4}
	 	 value={overviewComments}
	 	 onChange={(e) => setOverviewComments(e.target.value)}
	 	 placeholder="Enter details here..."
	  />
	  </Modal>
	  
	  <Modal 
	  title="Are you sure you want to regenerate your Writing Style?"
	  visible={isConfirmWritingStyleModalVisible}
	  onOk={() => handleConfirmWritingStyleModal(true)}
	  onCancel={() => handleConfirmWritingStyleModal(false)}
	  >
	  <p>If you proceed, the existing Writing Style text will be deleted and replaced. <br/>
	    
	  Regenerating Writing Style will NOT change any other text fields already generated.<br/><br/>
	  
	  Do you want to continue?</p>
	  <Checkbox onChange={(e) => setShouldSkipWritingStyleConfirm(e.target.checked)}>Don't show this again for this session</Checkbox>
	  </Modal>

<Modal 
  title="Trope Suggestions"
  visible={isTropeSuggestionsModalVisible}
  onOk={handleGenerateTropesWithSuggestions}
  onCancel={() => setIsTropeSuggestionsModalVisible(false)}
>
  <p>Enter any suggestions for the AI to consider when generating tropes (optional):</p>
  <Input.TextArea 
    rows={4}
    value={tropeSuggestions}
    onChange={(e) => setTropeSuggestions(e.target.value)}
    placeholder="Enter suggestions here..."
  />
</Modal>
	  
    </div>
	 </Spin>
  );
};

export default HighLevelTab;
