// About.tsx
import React from 'react';
import { Typography,Divider,Row, Col, Card, Collapse } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;

const About: React.FC = () => {
	
  const cardStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '20px',	
	};

  const customPanelStyle = {
    borderRadius: 4,
    marginBottom: 0,
    border: '1px solid #d9d9d9', // Default border style for panels
    overflow: 'hidden',
  };



  return (
    <div style={{ backgroundColor: '#f0f2f5', padding: '2em' }}>
      <div style={{ backgroundColor: '#ffffff', padding: '2em', borderRadius: '5px' }}>
        <Title style={{ margin: 0, flexGrow: 1, textAlign: 'center' }}>About</Title>
        <div style={{ textAlign: 'center', margin: '20px 0' }}>
          <a href="#faq">FAQ</a> | <a href="#updates">Updates</a> | <a href="./support">Support</a> | <a href="/support#videoTutorial">Tutorials</a>
        </div>		
        <div style={{ textAlign: 'right' }}>
          <a href="mailto:contact@scribeshadow.com">
            <FontAwesomeIcon icon={faEnvelope} style={{ fontSize: '24px', marginRight: '10px', color: '#1890ff' }} />
          </a>
          <a href="https://discord.com/invite/k7vnT38vPq" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faDiscord} style={{ fontSize: '24px', marginRight: '10px', color: '#1890ff' }} />
          </a>
          <a href="https://www.facebook.com/groups/216351367578868/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} style={{ fontSize: '24px', marginRight: '10px', color: '#1890ff' }} />
          </a>
        </div>

      <Divider />

      <Title id="faq" level={2} style={{ textAlign: 'center' }}>Frequently Asked Questions - ScribeShadow</Title>
      <Row gutter={16}>
        <Col xs={24} md={12}> 
<Collapse>
  <Panel header="How Does AI Translation Work?" key="1" style={customPanelStyle}>
    <p>AI translation employs sophisticated machine learning algorithms, trained on extensive text datasets, to provide accurate and fluent translations of novels.</p>
  </Panel>
  <Panel header="Do I Retain the Rights to AI Translations of My Work?" key="2" style={customPanelStyle}>
    <p>Yes, you retain all rights to both your original work and its AI-generated translations. ScribeShadow does not claim any rights to your content.</p>
  </Panel>
<Panel header="What Languages are Supported?" key="3" style={customPanelStyle}>
<p>ScribeShadow can translate to and from the following languages: Dutch, English, French, German, Italian, European Portuguese, Brazillian Portuguese, and Spanish. Other languages may also be available in a BETA state. Check our <a href="/betaaccess"> Beta Signup</a> page for more information on what is currently being beta tested.</p>  </Panel>

<Panel header="Is There a Limit to the Length of Novels That Can Be Translated?" key="4" style={customPanelStyle}>
<p>There is no strict limit to the length of novels that can be translated by our AI.</p>	</Panel>	

<Panel header="How Much Does ShadowScribe AI Translation Cost?" key="5" style={customPanelStyle}>
<p>For detailed information on pricing, visit our <a href="../account">account page</a>. ShadowScribe AI Translation offers an economically advantageous solution, typically costing over 90% less than professional human translation services.</p></Panel>

<Panel header="How Does AI Handle Cultural Nuances in Translation?" key="6" style={customPanelStyle}>
<p>AI translation actively works to not directly translate idioms, sayings, or culturally-specific expressions. Instead, it strives to find equivalent phrases or expressions in the target language that convey the original meaning as accurately as possible. This approach helps maintain the intended impact and cultural relevance of the original text.</p></Panel>

<Panel header="Is Editing Required for AI-Translated Text?" key="7" style={customPanelStyle}>
<p>AI translations are typically well receieved by readers due to their high quality, making the engagement of an editor not always essential. Ultimately, the decision to seek additional editing rests with the author, depending on their specific requirements and the context of the translated material.</p>	</Panel>	

<Panel header="What are Credits?" key="8" style={customPanelStyle}>
<p>Each word translated will use one credit. This also includes hidden metadata that we use to maintain as much formatting as possible. As such, the Credits cost may not exactly match up with your document's word count.</p>  </Panel>

<Panel header="What are Forever Credits?" key="9" style={customPanelStyle}>
<p>Credits purchased outside of your monthly plan are considered "Forever Credits" and never expire. Monthly Credits that come with your subscription plan do not roll over from month to month.</p>  </Panel>

<Panel header="Can I try out ScribeShadow?" key="10" style={customPanelStyle}>
<p>Absolutely! All new users are provided with 5000 credits when they signup to test out ScribeShadow.</p>  </Panel>

<Panel header="Do you use my books or translations for training AI?" key="11" style={customPanelStyle}>
<p>No. We do not use your books, translations, or any other data for training AI.</p>  </Panel>

<Panel header="Why Opt for AI Over Human Translators?" key="12" style={customPanelStyle}>
<p>Opting for AI translation presents a highly efficient and cost-effective solution, offering rapid turnaround times without compromising on quality. This modern approach to translation blends speed and affordability, while still achieving results that closely rival those of traditional human translators.</p></Panel>

 </Collapse>

        </Col>
          <Col xs={24} md={12}>
<Collapse>
<Panel header="Do you recommend a human proofreads or reviews my AI Translation?" key="22" style={customPanelStyle}>
<p>Having a human proofread or review your AI Translation can provide peace of mind that your translation is accurate, add a layer of humanization to the text, as well as open access to publishing platforms that do not allow AI-only translations.<br/><br/>For those seeking a human review, we've partnered with <a href="mailto:max@problemsolved.es">Max</a> from <a href="http://translation.problemsolved.es/" target="_blank">Problem Solved translation services</a>. Max and his translators offer an exclusive rate to ScribeShadow translated manuscripts as well as a certificate of authenticity, opening up those platforms that do not allow AI-only translations.</p>
<center><a href="http://translation.problemsolved.es/" target="_blank" rel="noopener noreferrer"><img src="/PSLogo.png" alt="Problem Solved Logo" style={{ width: '200px'}} /></a></center></Panel>

<Panel header="Can the AI Translate a Novel into Multiple Languages?" key="13" style={customPanelStyle}>
<p>Yes. Our AI is capable of translating a novel into any of our supported language. Each translation is handled individually to ensure accuracy and quality, allowing for a broad range of linguistic options to cater to diverse readerships.</p></Panel>

<Panel header="Will the Translation Preserve My Formatting, Such as Images, Bold, Italics, Etc.?" key="14" style={customPanelStyle}>
<p>The AI strives to maintain all original formatting, including images, bold, and italics, in translations. However, some formatting may be lost, so we recommend double-checking important formatting elements in the final novel.</p></Panel>

<Panel header="Can AI Translate Sensitive Content, Such as Erotic or Violent Material?" key="15" style={customPanelStyle}>
<p>Our AI algorithms are designed to handle a wide range of content, but some extremely sensitive or explicit material may be subject to rejection.</p></Panel>

<Panel header="If the Content of My Story Is Rejected, Will I Still Be Charged Translation Credits?" key="16" style={customPanelStyle}>
<p>In the event of a chapter's content being rejected, you will only be charged 25% of the credit cost for that chapter.</p></Panel>

<Panel header="Is There a Possibility of Success When Re-Submitting Previously Rejected Content for Translation?" key="17" style={customPanelStyle}>
<p>Re-submitting previously rejected content can result in successful translation, as the AI model's responses can vary.</p></Panel>

<Panel header="Can I Upload a Word Document and Download an ePub, or Vice-Versa?" key="18" style={customPanelStyle}>
<p>Novels uploaded from ePubs can be downloaded as either ePubs or Word documents. Novels uploaded as Word documents can only be downloaded as Word documents.</p>	</Panel>

<Panel header="What AI Model Do You Use?" key="19" style={customPanelStyle}>
<p>We currently use the latest versions of the Chat-GPT 4o and Claude 3.5 APIs. For translations, we customize the AI with tailored instructions based on the source and target languages.</p></Panel>

<Panel header="How Is ScribeShadow Different Than Just Using the Chat-GPT Web Interface?" key="20" style={customPanelStyle}>
<p>- ScribeShadow is much faster than feeding chapters (or parts of chapters) into Chat-GPT. ScribeShadow can translate an entire novel in minutes.<br />
- ScribeShadow uses custom-tailored instructions to modify and hone the AI's behavior when translating between languages.<br />
- ScribeShadow provides a curated way to store your books and their translations, keeping everything organized in one place.</p></Panel>

<Panel header="Do I need to credit ScribeShadow as my translator?" key="21" style={customPanelStyle}>
<p>No, we don't reqiure translation attribution. However, if you want or need to include a translation credit in your work, simply attribute your translation to ScribeShadow and include a link to <a href="https://app.scribeshadow.com">https://app.scribeshadow.com</a>.</p>  </Panel>

<Panel header="Do you keep my books or translations in your system after I delete them?" key="22" style={customPanelStyle}>
<p>No. Once you delete a book from our system, it is removed completely and cannot be accessed or recovered.</p>  </Panel>
 </Collapse>
            </Col>
          </Row>
{ /*
      <Divider />

<div id="create-faq">
      <Title id="faq2" level={2} style={{ textAlign: 'center', marginBottom: 10 }}>Frequently Asked Questions - Novel Creation</Title>
      <Title level={3} style={{ textAlign: 'center', margin: '10px 0' }}>
        Novel Creation is a BETA feature. <a href="/betaaccess">Sign up for BETA access here.</a>
      </Title>
      <Row gutter={16}>
        <Col xs={24} md={12}> 	
 <Collapse>		
			  <Panel header="How does AI novel generation work?" key="1" style={customPanelStyle}>
              <p>AI novel generation uses advanced machine learning algorithms, trained on large amounts of text data, to generate unique and coherent pieces of text that can range from a few sentences to a full-length novel.</p></Panel>
			  <Panel header="How many credits does each generation cost?" key="2" style={customPanelStyle}>
              <p>The generation cost varies depending on the next being generated. Longer and more complex text (such as characters and locations) cost more credits than simpler text (such as tropes or writing style). Outline and Novel generation credit costs depend on the length of your novel.</p></Panel>
			  <Panel header="How are credits used in novel generation?" key="25" style={customPanelStyle}>
              <p>Credits are consumed each time the AI generates a piece of text. The number of credits used depends on the length of the generated text.</p></Panel>
			  <Panel header="Does regenerating a piece of text I've already generated cost credits" key="26" style={customPanelStyle}>
              <p>Yes. Regenerating a field with AI is considered a new generation and will consume the number of credits indicated next to the button.</p></Panel>
			  <Panel header="If I regenerate a piece of text and don't like it, can I go back to the old version" key="27" style={customPanelStyle}>
              <p>No. Regenerating a piece of text completely replaces the old version. If you want to make small changes you can do so directly in the text box. If you don't like a generated piece of text at all, you may regenerate it until you like it. (Please note that each regeneration consumes credits.)</p></Panel>
			  <Panel header="What are the limitations of AI novel generation?" key="28" style={customPanelStyle}>
              <p>While AI can generate remarkably coherent and creative text, it is not perfect. It can sometimes produce text that is irrelevant, repetitive, or nonsensical.</p></Panel>
			  <Panel header="Can I edit the text in each object?" key="29" style={customPanelStyle}>
              <p>Yes. Feel free to edit the text within each object. We recommend editing the text in an object BEFORE moving onto the next object, as each subsequent generation builds on the ones before.</p>	</Panel>		  
 </Collapse>		
        </Col>
          <Col xs={24} md={12}>
		   <Collapse>		
              <Panel header="Can I publish my novel?" key="30" style={customPanelStyle}>
              <p>Yes! You can use any of the text generated by ScribeShadow however you want.</p>	</Panel>	  
              <Panel header="Does the text generated for my novel need to be edited?" key="31" style={customPanelStyle}>
              <p>While the grammar in the text produced by the AI is generally pretty good, we always recommend re-reading and editing your novel. The AI is not perfect and can sometimes produce unintended or inaccurate text that you may want to edit or remove from your final novel.</p></Panel>
			  <Panel header="Can I control the style of the novel?" key="32" style={customPanelStyle}>
              <p>Yes, you can guide the style of the AI's output by choosing the tense, point of view and describing the writing style you want the AI to emulate.</p></Panel>
			  <Panel header="Can I generate erotic or sexually explicit novels?" key="33" style={customPanelStyle}>
              <p>Maybe. The AI tends to avoid writing explicit content, but it will sometimes generate mild to moderatly explicit scenes if requested.</p></Panel>

			  <Panel header="If I regenerate an earlier object that's required for a later generation, will the later objects change?" key="34" style={customPanelStyle}>
              <p>No. The objects that have already been generated will not automatically change when an earlier object is regenerated.</p></Panel>
			  <Panel header="How many characters and locations can my book have?" key="35" style={customPanelStyle}>
              <p>Trial accounts are limited to 2 of each: Main Characters, Supporting Characters, and Locations.<br/>All paid accounts may have up to 5 of each: Main Characters, Supporting Characters, and Locations.</p>			  </Panel>
			  <Panel header="How long of a book can I generate?" key="36" style={customPanelStyle}>
    <p>The number of chapters allowed in the novel creation depends on your current plan. Current limits are as follows:</p>
    <ul>
        <li>Trial Account: 6 chapters</li>
        <li>Inkling: 10 chapters</li>
        <li>Storyteller: 25 chapters</li>
        <li>Wordsmith: 40 chapters</li>
        <li>Novelist: 75 Chapters</li>
        <li>Bestseller: 75 Chapters</li>
    </ul>	</Panel>
	 </Collapse>	
	</Col>
	</Row>
    </div>
*/}	
      <Divider />
  <div id="updates">
    <Title id="updates" level={2} style={{ textAlign: 'center', marginBottom: 10 }}>Updates</Title>
    <Collapse>

<Panel header="October 2024" key="october2024" style={customPanelStyle}>
    <Collapse>
<Panel header="October 2nd, 2024 Updates" key="2024-10-02" style={customPanelStyle}>
  <p><strong>Subscription Management Improvements</strong></p>
  <ul>
    <li>We've added a new "Renew Now" button that allows you to instantly renew your current plan. When you use this feature:
      <ul>
        <li>Your billing date will be updated to today</li>
        <li>You'll be charged for your current plan</li>
        <li>You'll receive fresh credits for your current plan</li>
      </ul>
    </li>
	</ul>
   
Due to limitations with our payment processing system, upgrades done mid billing cycle aren't billed or processed until the billing cycle renews.<br/>For those wishing to upgrade their plan mid billing cycle, you can follow these steps:
<ul>	
      <ol>
        <li>Use the "Manage Subscription" button to select your desired upgrade</li>
        <li>After selecting your new plan, use the "Renew Now" button to activate it instantly</li>
        <li>This process will update your billing date, charge you for the new plan, and provide you with the upgraded plan's credits right away</li>
      </ol>
   
    
  </ul>
</Panel>	
<Panel header="October 1st, 2024 Updates" key="2024-10-01" style={customPanelStyle}>
  <p><strong>Translation Updates</strong></p>
  <ul>
    <li>Made German Guillimets the default choice for German translations.</li>
  </ul>
  <p><strong>Bug Fixes</strong></p>
  <ul>
    <li>Fixed a bug where Llama was requiring beta access.</li>
  </ul>
  <p><strong>Billing System Improvements</strong></p>
  <ul>
    <li><s>Upgraded plans now receive full credit amount immediately.</s> <i>See October 2nd updates for clarification</i></li>
    <li>Downgraded plans' new credit amount takes effect on the next billing cycle.</li>
    <li>Eliminated complicated prorations for clearer and more predictable billing.</li>
    <li>These changes provide instant access to more credits when upgrading and simplify the overall billing process.</li>
  </ul>
</Panel>					
	  </Collapse>
	  </Panel>		
	
      <Panel header="September 2024" key="september2024" style={customPanelStyle}>
        <Collapse>
<Panel header="September 29th, 2024 Updates" key="2024-09-29" style={customPanelStyle}>
  <p><strong>AI Model Addition</strong></p>
  <ul>
    <li>Added a new model - Llama - based on Meta's Llama 3.2. Llama's quality is approximately on par with Chat-GPT.</li>
  </ul>
  <p><strong>Account Screen Updates</strong></p>
  <ul>
    <li>Added a link to the pricing page on the account screen for easier access to subscription information.</li>
    <li>Updated the account screen, renaming "Manage Billing" to "Manage subscription" for clarity.</li>
  </ul>
</Panel>		
<Panel header="September 23rd, 2024 Updates" key="2024-09-23" style={customPanelStyle}>
    <p><strong>Subscription Updates</strong></p>
    <ul>
    <li>We've streamlined our pricing structure by focusing on monthly subscriptions. If you're currently on an annual plan, don't worry - you can continue using it until your renewal date.</li>
    <li>Due to popular demand, we're introducing our new "Polyglot" subscription tier! This premium subscription offers an impressive 2,750,000 credits per month, perfect for power users.</li>
    </ul>
    <p><strong>User Interface Enhancements</strong></p>
    <ul>
    <li>We've added frequently asked questions about accounts and referrals directly to the account page for quick access.</li>
    </ul>
  </Panel>	
<Panel header="September 19th, 2024 Updates" key="2024-09-19" style={customPanelStyle}>
  <p><strong>AI Model Enhancements</strong></p>
  <ul>
    <li>Added DeepSeek and Claude 3 as new AI model options for generating blurbs, providing more choices for our users.</li>
    <li>Fixed a bug that prevented users from changing AI model selection when generating blurbs. You can now freely switch between models as needed.</li>
  </ul>
  <p><strong>French Translation Improvement</strong></p>
  <ul>
    <li>French translations now include non-breaking spaces before certain punctuation marks (such as :, !, ?, ;). This prevents these marks from appearing alone at the beginning of a new line, improving the overall layout and readability of your exported books.</li>
    <li>This change is retroactive: you can re-export your previously translated books to benefit from this improvement.</li>
  </ul>
<p><strong>ePub Export Enhancement</strong></p>
  <ul>
    <li>When exporting ePubs, the language code in the HTML/XML will now be automatically set to the translated language. This ensures that e-readers and other devices correctly recognize the language of your translated book, enhancing the reading experience for your audience.</li>
  </ul>  
</Panel>		
<Panel header="September 10th, 2024 Updates" key="2024-09-10" style={customPanelStyle}>
  <p><strong>UI Update</strong></p>
  <ul>
    <li>Added tags to model selection for recommended and other model features such as models that accept NSFW material.</li>
    <li>Introduced copy icons for translated title, series name, and blurb for easy copying.</li>
    <li>Enhanced blurb editing: You can now add formatting (bold, italics, underline) to both original and translated blurbs.</li>
  </ul>
  <p><strong>New Model!</strong></p>
  <ul>
    <li>Added DeepSeek. This model provides good translation accuracy while also allowing NSFW content. DeepSeek is also available at a 50% discount!</li>
  </ul>
  <p><strong>Account Settings</strong></p>
  <ul>
    <li>Added account settings (available in your account page <a href="https://app.scribeshadow.com/account">https://app.scribeshadow.com/account</a>). You can now set a default translation language that will apply when translating chapters, books, and metadata. To remove the default language select 'None' in the dropdown.</li>
  </ul>
</Panel>	
      <Panel header="September 7th, 2024 Updates" key="2024-09-07" style={customPanelStyle}>
        <p><strong>UI Updates</strong></p>
		<ul>
		<li>Move the Translate Chapter and Proofread Chapter buttons from above the Chapter List to above the chapter text.</li>
		<li>Decreased the size of the chapter names in the chapter list slightly.</li>
		<li>Changed "Translate" to "Translations" when selecting a book to better indicate that clicking the option isn't directly starting a translation.</li>
		</ul>
      </Panel>			
      <Panel header="September 3rd, 2024 Updates" key="2024-09-03" style={customPanelStyle}>
        <p><strong>Bug Fixes</strong></p>
		<ul>
		<li>Resolved an issue where some foreign language characters weren't being accepted in the always translate and never translate text boxes.</li>
		</ul>
      </Panel>		
      <Panel header="September 2nd, 2024 Updates" key="2024-09-02" style={customPanelStyle}>
        <p><strong>Rejected Content</strong></p>
		<ul>
		<li>We've made adjustments to how we flag, process, and translate chapters with content the AI may reject. This reduces our costs for these chapters and allows us to pass those savings onto you. As such, chapters now deduct only 10% (down from 25%) of the original chapter cost when the AI rejects them for content.</li>
		<li>We've enabled Claude 3 as a selectable model for translating. While not quite as good as Claude 3.5, Claude 3 is still produces a good quality translation and will often accept content that Claude 3.5 rejects.</li>
		</ul>
      </Panel>			  
	  </Collapse>
	  </Panel>	
		
      <Panel header="August 2024" key="august2024" style={customPanelStyle}>
        <Collapse>
<Panel header="August 31st, 2024 Updates" key="2024-08-26" style={customPanelStyle}>
  <p><strong>UI Update: Add language pair</strong></p>
  <ul>
    <li>In the Edit Metadata section there is now a Plus icon to the right of the language pair tabs. This icon will allow you to add new language to the list of tabs so you can add/edit metadata for that language.</li>
  </ul>
  <p><strong>New Feature: Additional Proofreading Instructions</strong></p>
  <ul>
    <li>When proofreading you can now give the AI additional instructions. You can use these instructions to direct the AI to do things such as change the tense of the chapter/book, change the tone, or make other specific changes during proofreading.</li>
  </ul>
  <p><strong>New Feature: Always Translate / Never Translate</strong></p>
  <ul>
    <li>Never Translate: You can now create a list of words or phrases that you don't want the AI to translate. This is perfect for names, places, or specific terms you want to keep in their original form.</li>
    <li>Always Translate: You can also create a list of words or phrases with specific translations you prefer. This ensures consistency in how certain terms are translated throughout your project.</li>
  </ul>
  How to use the Always/Never translate features:
  <ul>
    <li>Click on "Edit Metadata"</li>
    <li>Select the language you want to manage</li>
    <li>Look for the "Never Translate" or "Always Translate" sections</li>
    <li>Click the edit icon to add, remove, or modify your lists</li>
  </ul>
  <p><strong>Bug Fixes</strong></p>
  <ul>
    <li>Fixed a bug where Generating a blurb would error out if a Genre was entered but tropes were not</li>
    <li>Fixed a bug where the Original - Proofread tab wasn't always being displayed</li>
  </ul>
</Panel>			
      <Panel header="August 26th, 2024 Updates" key="2024-08-26" style={customPanelStyle}>
        <p><strong>File Import</strong></p>
		<ul>
		<li>Improved handling of formatting in word files. ScribeShadow should now keep formatting more consistently from certain word file coding.</li>
		</ul>
		<p><strong>UI Changes</strong></p>
		<ul>
		<li>Added a warning on the metadata page when metadata has been edited but not saved.</li>
		</ul>
      </Panel>			
      <Panel header="August 17th, 2024 Updates" key="2024-08-17" style={customPanelStyle}>
        <p><strong>Problem Solved Partnership</strong></p>
		<ul>
		<li>As part of our partnership with <a href="http://translation.problemsolved.es/" target="_blank" rel="noopener noreferrer">Problem Solved</a> for human review and translation, we've linked to their services in the FAQ and in the book metadata section.</li>
		<li>If you're interested in a human review and proofread of your ScribeShadow translated work, reach out to <a href="mailto:max@problemsolved.es">Max from Problem Solved</a> for exclusive ScribeShadow rates!</li>
		</ul>
		<p><strong>Default Selection Changes</strong></p>
		<ul>
		<li>The Translate Chapter and Translate Book dialogs should dynamically default to either Original or Original (Proofread) based on if there is a proofread version available.</li>
		<li>Removed the default selection from the Target Language in the Translate Chapter dialog.</li>
		<li>Removed the default selection from the Version to Proofread in the Proofread Chapter dialog.</li>
		<li>Removed the default selection from the Version to Proofread in the Proofread Book dialog.</li>
		<li>Removed the default selection from the Target Language in the Translate Metadata dialog.</li>
		</ul>
      </Panel>		
      <Panel header="August 15th, 2024 Updates" key="2024-08-15" style={customPanelStyle}>
        <p><strong>Backend Updates</strong></p>
		<ul>
		<li>Backend updates to help handle scaling as ScribeShadow grows. This should reduce the occurrence of the Chapters Failed Translation / Chapters Failed Proofread errors</li>
		<li>Credits from subscriptions, forever credit purchases, and affiliate rewards should now appear in your transaction history. Please note that only transactions moving forward will be listed.</li>
		</ul>
      </Panel>				
      <Panel header="August 12th, 2024 Updates" key="2024-08-12" style={customPanelStyle}>
        <p><strong>Translations</strong></p>
		<ul>
		<li>Added additional text to the translation confirmation prominently displaying the language being translated into. </li>
		<li>Updated the translation dialog so no language is initially selected.</li>
		</ul>
      </Panel>		
      <Panel header="August 9th, 2024 Updates" key="2024-08-09" style={customPanelStyle}>
        <p><strong>Translation Errors</strong></p>
		<ul>
		<li>Added additional messaging when chapters fail translation. The message will tell you that are not charged for failed translations, and will give you steps for translating your missing chapters. </li>
		</ul>
		<p><strong>Translation & Proofread backend</strong></p>
		<ul>
		<li>Updates to the Translation and Proofreading backend to utilize Claude 3.5's expanded token return length.</li>
		</ul>
      </Panel>			
      <Panel header="August 4th, 2024 Updates" key="2024-08-04" style={customPanelStyle}>
        <p><strong>Blurb Export</strong></p>
		<ul>
		<li>When exporting a blurb to text, the book's title will now be included in the filename.</li>
		</ul>
		<p><strong>New Beta Language</strong></p>
		<ul>
		<li>Added Hindi as a beta language. To access Beta languages, you can signup for <a href="/betaaccess">Beta access here.</a></li>
		</ul>
		Note: Hindi requires more usage from the AI to translate, and is therefore more expensive than other languages to translate. Hindi translations are ~2 credits per word.
      </Panel>		    
	  </Collapse>
	  </Panel>	
      <Panel header="July 2024" key="july2024" style={customPanelStyle}>
        <Collapse>
      <Panel header="July 23rd, 2024 Updates" key="2024-07-23" style={customPanelStyle}>
        <p><strong>My Books UI Updates</strong></p>
		<ul>
		<li>Added Filtering to the My Books Page.</li>
		</ul>
		You can now dynamically filter by Author and/or Series in the My Books page.
      </Panel>	
      <Panel header="July 22nd, 2024 Updates" key="2024-07-22" style={customPanelStyle}>
        <p><strong>Translated Metadata UI Updates</strong></p>
		<ul>
		<li>You can now edit and save the translated title, blurb, and series name for each book.</li>
		</ul>
      </Panel>	
      <Panel header="July 9th, 2024 Updates" key="2024-07-09" style={customPanelStyle}>
        <p><strong>Credit Usage History UI Updates</strong></p>
		<ul>
		<li>Added a dropdown to filter by Description.</li>
		<li>Updated the filters to be more dynamic and show what language and descriptions are available based on the filtered book.</li>
		<li>Added a total at the bottom of the table that updates based on the filters selected.</li>
		<li>Fixed a bug where the drop down to pick the number of transactions per page was not working.</li>
		<li>Fixed a bug where the date picker wasn't properly filtering by date.</li>
		<li>Fixed a bug where the chapter name was not being stored on transactions for proofreading (this will only effect logged transactions moving forward).</li>
		</ul>
      </Panel>			
      <Panel header="July 7th, 2024 Updates" key="2024-07-07" style={customPanelStyle}>
        <p><strong>New Tutorials!</strong></p>
		<ul>
		<li>We have added tutorials for many more processes in ScribeShadow! From uploading your book, to proofreading your translation, get guidance from our <a href="/support">support page.</a></li>
		</ul>
		<p></p>
		<p><strong>Pricing Page</strong></p>
		<ul>
		<li>We've added a stand-alone pricing page available from the drop-down user menu. Click on the icon at the top right of the page to access the new <a href="/pricing">pricing page.</a></li>
		</ul>
		<p></p>
		<p><strong>UI Updates</strong></p>
		<ul>
		<li>Claude 3.5 is now marked as the currently recommended AI model.<br/><em>(This recommendation will change over time as future AI models are released and integrated into ScribeShadow.)</em></li>
		<li>Fixed several small bugs in the UI; such as checkboxes not being disabled if the corresponding data was not populated.</li>
		</ul>
      </Panel>					
      <Panel header="July 5th, 2024 Updates" key="2024-07-05" style={customPanelStyle}>
        <p><strong>Translation Improvements!</strong></p>
		<p>We have enhanced ScribeShadow's translation capabilities by having the AI analyzing your characters' relationships within each chapter. This new feature, combined with the analysis from previous chapters, ensures more accurate and consistent use of pronouns.</p>
		<p><em>Please note, if you choose to translate chapters individually, we now recommend translating them in sequential order to maintain consistency in character relationships. ScribeShadow will also warn you if you try and translate a chapter where the previous chapter has not yet been translated. If you opt to translate the entire book at once, ScribeShadow will handle this seamlessly for you.</em></p>
      </Panel>			
      <Panel header="July 3rd, 2024 Updates" key="2024-07-03" style={customPanelStyle}>
        <p><strong>New Feature: Blurb Generation!</strong></p>
		<p>ScribeShadow can now use your manuscript to generate a compelling blurb for you! In the 'Edit Metadata' section, find the 'Generate Blurb' button to get started. You can then choose the Tense, PoV (and in the case of 1st person PoV) the name of the character whose PoV you want the blurb written from. ScribeShadow will then generate 3 blurb options for you to choose from.</p>
		<p><em>Note: Blurb generation uses a portion of your manuscript and costs ~10% of your manuscript's length, with a minimum of 500 credits and a maximum of 5000 credits per set of three blurb options.</em></p>
      </Panel>			
        </Collapse>
      </Panel>		
      <Panel header="June 2024" key="june2024" style={customPanelStyle}>
        <Collapse>
      <Panel header="June 30th, 2024 Updates" key="2024-06-30" style={customPanelStyle}>
        <p><strong>UI Updates:</strong></p>
		<ul>          
		  <li>Add a confirmation step when translating or proofreading an entire book to prevent accidental translations/proofreads.</li>
		</ul>  	
      </Panel>			
      <Panel header="June 29th, 2024 Updates" key="2024-06-29" style={customPanelStyle}>
        <p><strong>UI Updates:</strong></p>
		<ul>          
		  <li>Updated the layout of the Edit Metadata section when translating a book.</li>
		</ul>  
        <p><strong>Bug Fixes:</strong></p>
		<ul>   
			<li>Fixed a bug where rejected content wasn't being flagged as such to display to the user.</li>
        </ul>		
      </Panel>			
      <Panel header="June 28th, 2024 Updates" key="2024-06-28" style={customPanelStyle}>
        <p><strong>UI Updates:</strong></p>
		<ul>          
		  <li>Updated the layout of the My Account screen.</li>
		  <li>Added 'View Credit Transaction History' to the My Account screen. This will take you to a new page to show your credit spend history.</li>
		</ul>  
		  Please note that the Credit Transaction History screen will only be for credit transactions moving forward. We do not currently have a history of transactions to display. This display will also not display manually granted credits, referral credits, or purchased/subscription credits added to the account. We hope to add these in the future.

        <p><strong>Subscription Plan Updates:</strong></p>
		<ul>   
		  <li>Added a new Publisher tier subscription option. This tier offers 1,300,000 credits monthly.</li>
		</ul>  
        <p><strong>Bug Fixes:</strong></p>
		<ul>   
			<li>Fixed a bug where translated titles and series names were not saving to the database.</li>
        </ul>

      </Panel>			
      <Panel header="June 26th, 2024 Updates" key="2024-06-26" style={customPanelStyle}>
        <p><strong>Backend Updates:</strong></p>
		<ul>          
		  <li>Updated translation and proofreading so the AI is less likely to omit tags such as paragraph breaks that would cause sections of text to run together.</li>
		  <li>Updated proofreading backend so EM/EN dashes aren't replaced with standard dashes.</li>
        </ul>
				  <br/>
		  Updated the AI backend so the AI should no longer rarely introduce the translation/proofreading with text that wasn't part of the original document. ("Here is your translation: ")
      </Panel>			
      <Panel header="June 22nd, 2024 Updates" key="2024-06-22" style={customPanelStyle}>
        <p><strong>Proofreading Updates:</strong></p>
        Updated the In-line view for proofreading with the following changes:
		<ul>          
		  <li>The view should now keep some formatting such as italics, headers, and bold.</li>
		  <li>The view should now have less false positives. (Curly quotes to straight quotes for example.)</li>
		  <li>The view should now keep paragraph spacing. (In some cases spacing was lost and everything ran together.)</li>
        </ul>
				  <br/>
		  Updated the AI backend so the AI should no longer rarely introduce the translation/proofreading with text that wasn't part of the original document. ("Here is your translation: ")
      </Panel>			
      <Panel header="June 20th, 2024 Updates" key="2024-06-20" style={customPanelStyle}>
        <p><strong>AI Model Update:</strong></p>
        <ul>
          <li>Claude 3 (which used Claude 3 Sonnet) has been replaced with the superior Claude 3.5 Sonnet.</li>
        </ul>
      </Panel>			
      <Panel header="June 18th, 2024 Updates" key="2024-06-18" style={customPanelStyle}>
        <p><strong>Proofreading Improvements:</strong></p>
        <ul>
          <li>When proofreading German translations you can now select which dialog punctuation (German Quotation Marks [„Dialog Text“] or German Guillemets [»Dialog Text«]) you want the AI to use during proofreading.</li>
        </ul>
      </Panel>	
      <Panel header="June 17th, 2024 Updates" key="2024-06-17" style={customPanelStyle}>
        <p><strong>Export Update:</strong></p>
        <ul>
          <li>Enabled the ability to export a book to Word if the book was originally uploaded as an ePub.</li>
		  <em>Please note that the export is still experimental and some formatting and images may not export when converting.</em>
        </ul>
      </Panel>		  
      <Panel header="June 15th, 2024 Updates" key="2024-06-15" style={customPanelStyle}>
        <p><strong>Proofreading Improvements:</strong></p>
        <ul>
          <li>We want everyone to have the best translation possible. To facilitate this, we've discounted the Proofreading service by 50%!</li>
        </ul>
      </Panel>		
      <Panel header="June 13th, 2024 Updates" key="2024-06-13" style={customPanelStyle}>
        <p><strong>Translation Improvements:</strong></p>
        <ul>
          <li>You can now choose between German style quotation marks („“) and Guillemets (» «) when translating to German.</li>
		  <li>In the Translate Chapter / Book and Translate Blurb pop-ups, if German is the chosen language you'll also have a dropdown to choose either German style quotation marks or Guillemets.</li>
        </ul>
      </Panel>
      <Panel header="June 12th, 2024 Updates" key="2024-06-12" style={customPanelStyle}>
        <p><strong>Introducing our affiliate referral program!</strong></p>
        <ul>
          <li>By joining our referral program, you can earn free forever credits when your referral signs up for a subscription plan.</li>
          <li>By using your referral code, your referee will get 50% off their first month while you get a bonus worth 25% of their first month's credits as forever credits!</li>
          <li>To join the program, reach out to us via Discord, Facebook, or email and include the email address you signed up to ScribeShadow under.</li>
          <li>For more information, visit our FAQ page: <a href="https://app.scribeshadow.com/support#affiliateFaq">https://app.scribeshadow.com/support#affiliateFaq</a></li>
        </ul>
      </Panel>
      <Panel header="June 11th, 2024 Updates" key="2024-06-11" style={customPanelStyle}>
        <p><strong>UI Improvements:</strong></p>
        <ul>
          <li>The chapter list and chapter text can now be scrolled independently.</li>
          <li>Added a Copy Translated Text button to the top of the translated chapters. This will copy the formatted, translated text for the selected chapter to your clipboard.</li>
          <li>Added a Copy Proofread Text button to the top of the proofread chapters under the "Proofread Only" view. This will copy the formatted, proofread text for the selected chapter to your clipboard.</li>
        </ul>
      </Panel>	  
          <Panel header="June 10th, 2024 Updates" key="2024-06-10" style={customPanelStyle}>
            <p><strong>Improvements to the docX import:</strong></p>
            <ul>
              <li>ScribeShadow will now divide chapters properly at H1 headings as well as page breaks.</li>
              <li>Updated the support page based on the new docX guidelines.</li>
            </ul>
            <p><strong>Updates to the mobile experience:</strong></p>
            <ul>
              <li>Fixed the mobile header so the mobile menu has a drop down allowing navigation between Pricing, Support, and About when not logged in.</li>
              <li>Improved the layout on the Pricing screen for the pricing cards.</li>
			  <li>Improved the layout of the accout information card.</li>
            </ul>			
          </Panel>		
          <Panel header="June 4th, 2024 Updates" key="2024-06-04" style={customPanelStyle}>
            <p><strong>UI Updates:</strong></p>
            <ul>
              <li>Added an About tab to the header.</li>
              <li>We have split our existing Support page with the more generic FAQ and Updates moved to the About section.</li>
              <li>We have updated the Support page FAQ with more detailed questions regarding the translation process and common questions users may encounter while working with ScribeShadow.</li>
            </ul>
          </Panel>		  		
          <Panel header="June 2nd, 2024 Updates" key="2024-06-02" style={customPanelStyle}>
            <p><strong>New Feature:</strong></p>
            <ul>
              <li>ScribeShadow can now suggest translated keywords for your books!</li>
              <li>New fields in the Edit Metadata section allow you to set tropes and keywords relevant to your books. These can be used with the new Suggest Keywords feature.</li>
              <li>To use the Suggest Keywords feature, locate the Suggest Keywords button in the Edit Metadata section. This will open a pop-up where you can select the language for keyword suggestions and choose which data ScribeShadow should use from: Genre, Tropes, Keywords, and Blurb.</li>
            </ul>
            <p><strong>UI Changes:</strong></p>
            <ul>
              <li>Added the ability to select your preferred AI Model in the Translate Metadata pop-up.</li>
            </ul>
          </Panel>
        </Collapse>
      </Panel>

      <Panel header="May 2024" key="may2024" style={customPanelStyle}>
        <Collapse>

          <Panel header="May 17th, 2024 Updates" key="2024-05-17" style={customPanelStyle}>
            <p><strong>New Feature:</strong></p>
            <ul>
              <li>You can now proofread your translated text! The Proofread dialog includes a version option where you can choose either your originally uploaded chapter/book or one of your translated chapters/books to proofread.</li>
              <li>Once your proofreading is complete, click Export Book and choose the proofread version of your language pair to export the proofread text.</li>
              <li>Proofreading translated chapters/books replaces the Precision Boost feature, which has been removed.</li>
              <li>To access Thai the Proofreading feature, signup for <a href="/betaaccess">BETA access here.</a></li>
            </ul>
            <p><strong>UI Changes:</strong></p>
            <ul>
              <li>When selecting a chapter, you will now find a dropdown that lists your original text as well as any language pairs you have translated. Selecting an option from this box will give you two tabs for the chosen language: the original translation and the proofread version.</li>
            </ul>
          </Panel>

          <Panel header="May 14th, 2024 Updates" key="2024-05-14" style={customPanelStyle}>
            <p><strong>Translation Changes:</strong></p>
            <ul>
              <li>Thai has been added as a BETA language and is available to be translated to and from. To access Thai and our other BETA languages, signup for <a href="/betaaccess">BETA access here.</a></li>
              <li>All BETA languages are now available as source languages when uploading a new book.</li>
            </ul>
          </Panel>

          <Panel header="May 13th, 2024 Updates" key="2024-05-13" style={customPanelStyle}>
            <p><strong>Model Changes:</strong></p>
            <ul>
              <li>The Chat-GPT model now uses the newest, and more capable, GPT-4o model! This model is faster and should produce higher quality translations than the previous GPT-4 model.</li>
            </ul>
            <p><strong>Translation Changes:</strong></p>
            <ul>
              <li>Precision Boost has returned. When translating a chapter or your book, you can enable precision boost for a second check of your translation by the AI.</li>
              <li>Tweaks have been made to the backend to improve translation quality across the following languages: English, British English, French, German, Dutch, Italian, Spanish.</li>
              <li>Updated backend logic to prevent missing paragraph tags which would sometimes cause paragraphs to run together in translated chapters.</li>
            </ul>
          </Panel>

          <Panel header="May 8th, 2024 Updates" key="2024-05-08" style={customPanelStyle}>
            <p><strong>Misc Changes:</strong></p>
            <ul>
              <li>Added text to the pop-ups for Proofread Book and Translate Book indicating that ScribeShadow will only Proofread/Translate chapters that have not yet been Proofread/Translated.</li>
            </ul>
            <p><strong>Bug Fixes:</strong></p>
            <ul>
              <li>Fixed a bug causing errors when requesting editing notes during proofreading.</li>
            </ul>
          </Panel>

          <Panel header="May 7th, 2024 Updates" key="2024-05-07" style={customPanelStyle}>
            <p><strong>Translation Changes:</strong></p>
            <ul>
              <li>Added a 'Delete Translated Chapters' button. This button will quickly delete all of the translated chapters for the chosen language at once. You can find this button in the Edit Metadata section, under the language tab of the translations you want to delete.</li>
            </ul>
            <p><strong>Bug Fixes:</strong></p>
            <ul>
              <li>Fixed a bug that prevented ePub files with certain HREF elements from exporting.</li>
            </ul>
          </Panel>

          <Panel header="May 6th, 2024 Updates" key="2024-05-06" style={customPanelStyle}>
            <p><strong>Proofreading Changes:</strong></p>
            <ul>
              <li>Added a "Tense" value to the Proofreading pop-up. You can now specify what tense (Past, Present, Future) your chapter/book is written in so ScribeShadow can better proofread your chapter.</li>
              <li>Improved proofreading backend. Claude should add extra text (such as "Here is your proofread chapter:") much less often.</li>
              <li>Fixed some issues with exporting the proofread version of certain documents.</li>
            </ul>
          </Panel>
        </Collapse>
      </Panel>

      <Panel header="April 2024" key="april2024" style={customPanelStyle}>
        <Collapse>
          <Panel header="April 30th, 2024 Updates" key="2024-04-30" style={customPanelStyle}>
            <p><strong>Changes:</strong></p>
            <ul>
              <li>Promoted Claude 3 from Beta. All users can now access Claude 3.</li>
              <li>Set Claude 3 as the default model. Overall Claude appears to produce slightly better quality translations and we recommend using Claude in most cases. Chat-GPT may still perform better with spicy or other "objectionable" content.</li>
              <li>Removed the precision boost option while we work on improvements.</li>
            </ul>
          </Panel>

          <Panel header="April 27th, 2024 Updates" key="2024-04-27" style={customPanelStyle}>
            <p><strong>Support:</strong></p>
            <p>Added a video tutorial on the translation process. You can find it on our <a href="https://app.scribeshadow.com/support">support page</a> or on <a href="https://youtu.be/yEkisT8puOU">Youtube.</a></p>
          </Panel>

          <Panel header="April 20th, 2024 Updates" key="2024-04-20" style={customPanelStyle}>
            <p><strong>Bug Fixes:</strong></p>
            <p>Fixed an issue where some translated text would occasionally be duplicated.</p>
            <p><strong>Other:</strong></p>
            <p>Novel creation has been temporarily disabled while we work on some improvements.</p>
          </Panel>

          <Panel header="April 17th, 2024 Updates" key="2024-04-17" style={customPanelStyle}>
            <p><strong>Introducing Precision Boost for Translations:</strong></p>
            <p>When translating chapters or entire books, you can now opt for the Precision Boost feature. This enhancement allows your text to undergo a second review by our AI to enhance translation quality. The additional cost for Precision Boost is just 50% of the initial translation credit cost.</p>
            <p><strong>Updates to French Translations:</strong></p>
            <ul>
              <li>Translations into French will now utilize the more contemporary Em-Dash (—) for dialogue, replacing the traditional Guillemets (« »).</li>
              <li>The passé composé should now be employed consistently for expressing past actions instead of the less commonly used passé simple.</li>
            </ul>
            <p><strong>Minor Changes:</strong></p>
            <ul>
              <li>Minor updates to improve quality when translating into English, Russian, Chinese (Traditional and Simple), and Dutch.</li>
            </ul>
          </Panel>
        </Collapse>
      </Panel>
    </Collapse>
  </div>	</div>
    </div>
  );
};

export default About;
