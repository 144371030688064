// Support.tsx
import React from 'react';
import { Typography,Divider,Row, Col, Card, Collapse } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;

const Support: React.FC = () => {
	
  const cardStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '20px',	
	};

  const customPanelStyle = {
    borderRadius: 4,
    marginBottom: 0,
    border: '1px solid #d9d9d9', // Default border style for panels
    overflow: 'hidden',
  };

  const collapseContainerStyle = {
    maxWidth: '800px', // Adjust the width as needed
    margin: '0 auto', // Center the container
  };

  return (
    <div style={{ backgroundColor: '#f0f2f5', padding: '2em' }}>
      <div style={{ backgroundColor: '#ffffff', padding: '2em', borderRadius: '5px' }}>
        <Title style={{ margin: 0, flexGrow: 1, textAlign: 'center' }}>Support</Title>
        <div style={{ textAlign: 'center', margin: '20px 0' }}>
          <a href="#videoTutorial">Tutorials</a> | <a href="#accountfaq">Account FAQ</a> | <a href="#faq">Translations FAQ</a> | <a href="#word-formatting-guidelines">Word Formatting Guidelines</a> | <a href="#affiliateFaq">Referral Program FAQ</a> 
        </div>		
        <div style={{ textAlign: 'right' }}>
          <a href="mailto:contact@scribeshadow.com">
            <FontAwesomeIcon icon={faEnvelope} style={{ fontSize: '24px', marginRight: '10px', color: '#1890ff' }} />
          </a>
          <a href="https://discord.com/invite/k7vnT38vPq" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faDiscord} style={{ fontSize: '24px', marginRight: '10px', color: '#1890ff' }} />
          </a>
          <a href="https://www.facebook.com/groups/216351367578868/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} style={{ fontSize: '24px', marginRight: '10px', color: '#1890ff' }} />
          </a>
        </div>
<Divider />

<Title id="videoTutorial" level={2} style={{ textAlign: 'center' }}>Tutorials</Title>
<Row gutter={[16, 16]} style={{ maxWidth: '800px', margin: '0 auto' }} justify="center">
  {[
    { title: "Uploading Your Book and Editing its Metadata", url: "https://www.scribeshadow.com/uploadingmetadataedits" },
    { title: "Proofreading your Book in its Original Language", url: "https://www.scribeshadow.com/proofreadingyouroriginalbook" },
    { title: "Translating your Book", url: "https://www.scribeshadow.com/translatingyourbook" },
    { title: "Translating your Metadata", url: "https://www.scribeshadow.com/translatingyourmetadataandkeywords" },
    { title: "Proofreading your Translation", url: "https://www.scribeshadow.com/proofreadingyourtranslations" },
    { title: "Generating a Blurb", url: "https://www.scribeshadow.com/blurbsuggestions" }
  ].map((item, index) => (
    <Col xs={24} sm={12} md={8} key={index}>
      <Card
        hoverable
        style={{ height: '100%', textAlign: 'center', position: 'relative' }}
        bodyStyle={{ 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100%' 
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '10px',
            left: '10px',
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            backgroundColor: '#1890ff',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'bold',
            fontSize: '16px',
            zIndex: 1
          }}
        >
          {index + 1}
        </div>
        <a 
          href={item.url} 
          target="_blank" 
          rel="noopener noreferrer"
          style={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            height: '100%',
            padding: '20px',
            textDecoration: 'none',
            color: 'inherit'
          }}
        >
          {item.title}
        </a>
      </Card>
    </Col>
  ))}
</Row>
<Divider />
      <Title id="accountfaq" level={2} style={{ textAlign: 'center' }}>Frequently Asked Questions - Account Management</Title>
      <Row gutter={16}>
        <Col xs={24} md={12}> 
<Collapse>
  <Panel header="Can I upgrade/downgrade/cancel my Subscription?" key="1" style={customPanelStyle}>
    <p>Yes, you can upgrade, downgrade, or cancel your subscription at any time. To manage your subscription click the "Manage Billing" button in the <a href="./account">account page</a></p>
  </Panel>
  <Panel header="Do my subscription credits expire?" key="2" style={customPanelStyle}>
    <p>Yes, credits granted with your monthly subscription expire at the end of your subscription period. Forever credits, which do not expire, can also be purchased a la carte.</p>
  </Panel>  
</Collapse>
        </Col>
          <Col xs={24} md={12}>
<Collapse>  
  <Panel header="When does my subscription renew?" key="1" style={customPanelStyle}>
    <p>Subscriptions renew monthly and the date and TIME you signed up. So if you signed up on the 17th at 2:45pm, your account will renew each month on the 17th at 2:45pm.</p>
  </Panel>
  <Panel header="How does billing work when I upgrade or downgrade my plan?" key="4" style={customPanelStyle}>
    <p>When you upgrade your plan, you'll immediately receive the full amount of credits for your new plan. If you downgrade, your new credit amount will take effect on your next billing cycle.</p>
  </Panel>  
</Collapse>
            </Col>
          </Row>
        
<Divider />
      <Title id="faq" level={2} style={{ textAlign: 'center' }}>Frequently Asked Questions - Translations</Title>
      <Row gutter={16}>
        <Col xs={24} md={12}> 
<Collapse>
  <Panel header="Why isn't all of my book's formatting showing in ScribeShadow?" key="1" style={customPanelStyle}>
    <p>Both Word and ePub files can have text formatted in various ways. ScribeShadow tries to preserve as much formatting as possible. Some formatting may not display in the ScribeShadow preview but will appear once your translation is exported. Some complex formatting may be lost and will need to be re-applied after exporting your translation from ScribeShadow.</p>
  </Panel>
  <Panel header="Why does my translated chapter have mixed dialog punctuation?" key="2" style={customPanelStyle}>
    <p>Some languages have multiple acceptable punctuation styles for dialog. For example, Brazilian Portuguese literature can use either em-dashes or double quotes, while French literature can use either Guillemets (« ») or em-dashes. ScribeShadow instructs the AI to use the more accepted modern usage (the em-dash for both Brazilian Portuguese and French for example). However, the AI's training data contains examples of both, and the AI may not always use the expected dialog punctuation correctly.</p>
  </Panel>
  <Panel header="How do I fix chapters with incorrect or mixed dialog punctuation?" key="3" style={customPanelStyle}>
    <p>Re-translating or re-proofreading the chapter will typically fix the incorrect punctuation. You can also try using another AI model for re-translation or re-proofreading. Alternatively, you can manually fix them yourself using .docx or .epub editing software after exporting your translation.</p>
  </Panel>
  <Panel header="I translated my chapter. Where is it?" key="4" style={customPanelStyle}>
    <p>To view your translated chapter, selected the chapter you want to view and then click the drop down box in the right panel to choose the translated language.</p>
  </Panel>  
      <Panel header="Why does Amazon show so many spelling errors on my German translation?" key="6" style={customPanelStyle}>
        <p>German is very complex with many compound words. Amazon's spell-checker may not always recognize certain German words, especially compound words or regional variations.</p>
      </Panel>
      
      <Panel header="Why are there spaces between the end of the sentence and the punctuation (question mark, exclamation mark, etc) in French?" key="7" style={customPanelStyle}>
        <p>In French typography, it's standard to use non-breaking spaces before certain punctuation marks like question marks, exclamation points, colons, and semicolons. This is a correct feature of French writing, not an error.</p>
      </Panel>  
      <Panel header="Should I use High/Low quotation marks or Guillemets for German translations?" key="8" style={customPanelStyle}>
        <p>Both styles are acceptable in German writing. Traditionally, German texts use low/high quotation marks („example"), but guillemets (»example«) are also common, especially in modern literature. Choose the style that's most appropriate for your target audience or publisher's preferences.</p>
      </Panel>	  
</Collapse>
        </Col>
          <Col xs={24} md={12}>
<Collapse>
  <Panel header="Why isn't Amazon accepting my ePub?" key="12" style={customPanelStyle}>
    <p>While we take steps to ensure all necessary elements are included, it's possible that a tag or other required element was lost during translation. ePub editing software like <a href="https://sigil-ebook.com/sigil/download/">Sigil</a> can automatically fix most common errors in ePub files.</p>
  </Panel>
  <Panel header="Can I edit my original or translated text in ScribeShadow?" key="13" style={customPanelStyle}>
    <p>No, ScribeShadow does not allow editing of the original or translated text. Any changes need to be made before uploading or after exporting.</p>
  </Panel>
  <Panel header="How can I improve my translated keyword suggestions?" key="14" style={customPanelStyle}>
    <p>To improve translated keyword suggestions, ensure that the Primary Genre, Tropes, and Keywords sections are filled out in your book's Metadata section in ScribeShadow.</p>
  </Panel>
  <Panel header="What file formats can I export my translation to?" key="15" style={customPanelStyle}>
    <p>Books uploaded from a Word document can only be exported as Word documents. Books uploaded from ePubs can be exported as either ePub or Word documents.</p>
  </Panel>  
    <Panel header="One or more of my chapters failed translation. What do I do?" key="5" style={customPanelStyle}>
		<p>
		Don't worry! You haven't been charged for these chapters. You can easily retry the translation for these chapters using either of these methods:
		<ul>
		<li>Use the "Translate Chapter" button to translate individual chapters</li>
		<li>Or use the "Translate Book" button to translate all untranslated chapters at once</li>
		</ul>
		Note: Using the "Translate Book" button will only translate chapters that haven't been translated yet and you won't be charged again for chapters that have already been translated.
		</p>	
  </Panel>    
<Panel header="How can I access platforms that don't allow AI-only translations?" key="16" style={customPanelStyle}>
<p>For those seeking access to platforms that don't allow AI translations, we've partnered with <a href="mailto:max@problemsolved.es">Max</a> from <a href="http://translation.problemsolved.es/" target="_blank">Problem Solved translation services</a>.<br/><br/>After reviewing, proofreading, and humanizing your manuscript, Max and his translators offer a certificate of authenticity. This certificate declares that your manuscript has been reviewed, proofread, and humanized by a professional translator, thus opening up those platforms that do not allow AI-only translations. Best of all, ScribeShadow customers qualify for an exclusive rate for Max's services.</p>
<center><a href="http://translation.problemsolved.es/" target="_blank" rel="noopener noreferrer"><img src="/PSLogo.png" alt="Problem Solved Logo" style={{ width: '200px'}} /></a></center>
</Panel> 
 
</Collapse>
            </Col>
          </Row>
      <Divider />
<div id="word-formatting-guidelines">
  <Title level={2} style={{ textAlign: 'center' }}>Word Formatting Guidelines</Title>

  <p>
    <center>For the best experience and optimal results with our translation service, 
    please adhere to the following guidelines when preparing your Word documents.<br />Following these guidelines helps ensure your document is correctly formatted and ready for import into ScribeShadow.</center>
  </p>

  <ul>
    <li><strong>File Format:</strong> Ensure that your file is in <code>.docx</code> format. Files in <code>.doc</code> format are not supported and will require conversion before uploading.</li>
    <li><strong>Chapter Breaks:</strong> You can use page breaks and/or the H1 heading style to break up chapters.</li>
    <li><strong>Language Selection:</strong> When uploading your file, accurately select the written language of your document.</li>
    <li><strong>Complex Formatting:</strong> Be aware that some complex formatting elements, such as section breaks, headers, and footers, may be lost when using <code>.docx</code> files.</li>
    <li><strong>Table of Contents:</strong> A Table of Contents should not be included in your document. During translation the page numbers that chapters start on will likely change.</li>	
  </ul>

  {/*
<Title level={4} style={{ textAlign: 'center', margin: '20px 0' }}>Chapter Naming Examples</Title>
<Row gutter={[16, 32]} justify="space-around" align="stretch">
  {['numeric number', 'written number', 'numeric number', 'Written number'].map((type, index) => {
    // Determine the title and description based on the index
    let title;
    let description;
    switch (index) {
      case 0:
        title = "Chapter [numeric number]";
        description = "Example:";
        break;
      case 1:
        title = "Chapter [written number]";
        description = "Example:";
        break;
      case 2:
        title = "[numeric number]";
        description = "Example:";
        break;
      case 3:
        title = "[Written number]";
        description = "Example:";
        break;
      default:
        title = "";
        description = "";
        break;
    }

    return (
      <Col key={type} xs={24} sm={12} style={{ marginBottom: '20px' }}>
        <Card
          hoverable
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between', // This ensures space distribution within the card
            alignItems: 'center',
            padding: '20px',
            minHeight: '100%', // Ensures card takes full height of column
          }}
        >
          <Card.Meta
            title={title}
            description={description}
            style={{ textAlign: 'center', marginBottom: '20px' }} // Added margin for spacing
          />
          <img alt={`Chapter ${type}`} src={`/chaptername${index + 1}.PNG`} style={{ width: '100%', objectFit: 'cover', flexGrow: 1 }} />
        </Card>
      </Col>
    );
  })}
</Row>
  */}
      </div>

<Divider />
      <Title id="affiliateFaq" level={2} style={{ textAlign: 'center' }}>Frequently Asked Questions - Referral Program</Title>
      <Row gutter={16}>
        <Col xs={24} md={12}> 
          <Collapse>
            <Panel header="What is the referral program?" key="1" style={customPanelStyle}>
              <p>
                The referral program allows you to earn rewards by sharing your referral code with new users. When they use your code to subscribe, they get 50% off their first month's subscription, and you earn 25% of their first month's credits as forever credits.
              </p>
            </Panel>
            <Panel header="How do I get a referral code?" key="2" style={customPanelStyle}>
              <p>
                To get a referral code, you must email us at <a href="mailto:contact@scribeshadow.com">contact@scribeshadow.com</a> and include the email address you signed up under. Once we generate your code, it will be available in your account screen.
              </p>
            </Panel>
            <Panel header="Where can I view my referral code?" key="3" style={customPanelStyle}>
              <p>
                You can view your referral code on the account screen. Simply log in to your account and navigate to the account section to find your referral code.
              </p>
            </Panel>
            <Panel header="What do me and my referral get by using the code?" key="12" style={customPanelStyle}>
              <p>
                By using the referral code, the new user gets 50% off their first month's subscription. As the referrer, you will receive 25% of the new user's first month's credits as forever credits, which you can use without expiration.
              </p>
            </Panel>			
            <Panel header="Can my Referral Code be used on any plan?" key="13" style={customPanelStyle}>
              <p>
                No. Referall codes cannot be redeemed when signing up for the Publisher or Polyglot plans.
              </p>
            </Panel>						
          </Collapse>		  
        </Col>
        <Col xs={24} md={12}>
          <Collapse>

            <Panel header="Who can use my referral code?" key="13" style={customPanelStyle}>
              <p>
                The referral code is valid for new subscribers only. It cannot be used for a la carte credit purchases or by existing subscribers.
              </p>
            </Panel>
            <Panel header="How do I earn credits from referrals?" key="14" style={customPanelStyle}>
              <p>
                When someone uses your referral code to subscribe, you earn 25% of their first month's credits as forever credits. These credits will be automatically added to your account after the new user sets up their subscription.
              </p>
            </Panel>
            <Panel header="Can someone use my referral code with other promotions?" key="15" style={customPanelStyle}>
              <p>
                No, the referral code is specifically for new user subscriptions. It cannot be combined with other promotions or used for a la carte credit purchases.
              </p>
            </Panel>
            <Panel header="What do I do if I referred someone but don't see my referral credits?" key="16" style={customPanelStyle}>
              <p>
                If you believe someone signed up with your referral code but you don't see the credits in your account, reach out to us at <a href="mailto:contact@scribeshadow.com">contact@scribeshadow.com</a> with your details.
              </p>
            </Panel>			
          </Collapse>
            </Col>
          </Row>

	</div>
    </div>
  );
};

export default Support;
