import React, { useEffect, useState } from 'react';
import { Avatar, Dropdown, Layout, Menu, Typography, Button, Grid, message, Tooltip, Alert } from 'antd';
import { Link, NavLink } from 'react-router-dom';
import { User } from 'firebase/auth';
import { UserOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import styled from 'styled-components';
import axios from 'axios';


const { Title } = Typography;
const { Item, Divider } = Menu;
const { Header } = Layout;
const { useBreakpoint } = Grid;
const { Text } = Typography;

const StyledLink = styled(NavLink)`
  display: inline-block;
  margin-bottom: -1px;
  border-bottom: 1px solid transparent;
  transition: border-color 0.3s;

  &.active {
    border-color: currentColor;
  }

  &:hover, &:active {
    border-color: currentColor;
  }
`;


interface Props {
  showLoginModal: () => void;
  signOut: () => void;
  user: User | null;
  usersSharedState: any;
}

const HeaderComponent: React.FC<Props> = ({ showLoginModal, signOut, user, usersSharedState }) => {

  const screens = useBreakpoint();

  const displayName = user?.displayName || (user?.email ? user.email.split('@')[0] : '');
  const [isBetaUser, setIsBetaUser] = useState(false);
  const [planID, setPlanID] = useState("");
  const accountType = usersSharedState?.accountType;

  const today = new Date();
  const expirationDate = new Date('2024-09-01');
  const isBeforeExpiration = today < expirationDate;

  const shouldShowBanner = (isBeforeExpiration && (!user || planID === 'poeTrial'));

  const [hasUserClosedPSBanner, setHasUserClosedPSBanner] = useState(false);
  const [lastShownPSBanner, setLastShownPSBanner] = useState<Date | null>(null);
  const [showPSBanner, setShowPSBanner] = useState(false);
  const [showSpecialOfferBanner, setShowSpecialOfferBanner] = useState(false);

  useEffect(() => {
    const checkPSBannerStatus = async () => {
      if (user) {
        try {
          const response = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/check-ps-banner-status/${user.uid}`);
          setHasUserClosedPSBanner(response.data.hasUserClosedPSBanner);
          setLastShownPSBanner(response.data.lastShownPSBanner ? new Date(response.data.lastShownPSBanner) : null);
          
          // Determine if the PS banner should be shown
          const shouldShowPSBanner = !response.data.hasUserClosedPSBanner || 
            !response.data.lastShownPSBanner || 
            (new Date().getTime() - new Date(response.data.lastShownPSBanner).getTime()) > 14 * 24 * 60 * 60 * 1000;
          
          setShowPSBanner(shouldShowPSBanner);
        } catch (error) {
          console.error('Error checking PS banner status:', error);
        }
      }
    };

    checkPSBannerStatus();
  }, [user]);

  useEffect(() => {
    if (user && (!hasUserClosedPSBanner || !lastShownPSBanner || (new Date().getTime() - lastShownPSBanner.getTime()) > 14 * 24 * 60 * 60 * 1000)) {
      setShowPSBanner(true);
    } else {
      setShowPSBanner(false);
    }
  }, [user, hasUserClosedPSBanner, lastShownPSBanner]);

  useEffect(() => {
    const checkSpecialOfferBannerStatus = () => {
      const today = new Date();
      const expirationDate = new Date('2025-09-01');
      const isBeforeExpiration = today < expirationDate;
      const shouldShowBanner = isBeforeExpiration && (!user || planID === 'poeTrial');
      
      setShowSpecialOfferBanner(shouldShowBanner);
    };

    checkSpecialOfferBannerStatus();
  }, [user, planID]);  
  
  const handleClosePSBanner = async () => {
    if (user) {
      try {
        await axios.post(`https://askpoeai-nswdzfky7q-uc.a.run.app/update-ps-banner-status/${user.uid}`, {
          hasUserClosedPSBanner: true,
          lastShownPSBanner: new Date().toISOString(),
        });
        setShowPSBanner(false);
      } catch (error) {
        console.error('Error updating PS banner status:', error);
      }
    }
  };  
  
const handleResetPassword = async () => {
  if (user && user.email && user.providerData[0]?.providerId === 'password') {
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, user.email);
      message.success('Password reset email sent!');
    } catch (error) {
      message.error('Error sending password reset email. Please try again.');
    }
  } else {
    message.error('No user logged in.');
  }
};

useEffect(() => {
  // Check if the user is part of the beta program
  const userId = user?.uid;

  const checkBetaStatus = async () => {
    if (userId) {
      try {
        const response = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/check-beta-status/${userId}`);
        setIsBetaUser(response.data.isBetaUser);
      } catch (error) {
        console.error('Error checking beta status:', error);
      }
    }
  };

  const checkCredits = async () => {
    if (userId) {
      try {
        const creditResponse = await axios.get('https://askpoeai-nswdzfky7q-uc.a.run.app/check_credit_balance/', {
          params: {
            userID: userId,
          }
        });
        setPlanID(creditResponse.data.planID);
      } catch (error) {
        console.error('Error checking credits:', error);
      }
    }
  };

  checkBetaStatus();
  checkCredits();
}, [user?.uid]);



const userMenu = (
  <Menu>
    <Menu.Item key="0" disabled>
      <div>{displayName}</div>
    </Menu.Item>
    <Menu.Item key="1">
      <Link to="/account">Account</Link>
    </Menu.Item>	
    <Menu.Divider />
    {user && user.email && user.providerData[0]?.providerId === 'password' ? (
      <Menu.Item key="2">
        <span onClick={handleResetPassword}>Reset Password</span>
      </Menu.Item>
    ) : null}
      <Menu.Item key="pricing">
        <Link to="/pricing">Pricing</Link>
      </Menu.Item>		
      <Menu.Item key="betaAccess">
        <Link to="/betaaccess">Beta Access</Link>
      </Menu.Item>	
    <Menu.Item key="3" onClick={signOut}>
      Sign Out
    </Menu.Item>
  </Menu>
);  

const mobileMenu = (
  <Menu>
    {/* <Menu.Item key="create"><Link to="/create">Create</Link></Menu.Item> */}
	<Menu.Item key="manageBooks"><Link to="/manage-books">My Books</Link></Menu.Item> 
    <Menu.Item key="account"><Link to="/account">Account</Link></Menu.Item>
	<Menu.Item key="pricing"><Link to="/pricing">Pricing</Link></Menu.Item>
    <Menu.Item key="support"><Link to="/support">Support</Link></Menu.Item>
    <Menu.Item key="about"><Link to="/about">About</Link></Menu.Item>	
    <Divider />
    {user && user.email && user.providerData[0]?.providerId === 'password' ? (
      <Menu.Item key="resetPass">
        <span onClick={handleResetPassword}>Reset Password</span>
      </Menu.Item>	  
    ) : null}
      <Menu.Item key="betaAccess">
        <Link to="/betaaccess">Beta Access</Link>
      </Menu.Item>	
    <Menu.Item key="signOut" onClick={signOut}>Sign Out</Menu.Item>
  </Menu>
);

  const mobileGuestMenu = (
    <Menu>
      <Menu.Item key="pricing"><Link to="/pricing">Pricing</Link></Menu.Item>
      <Menu.Item key="support"><Link to="/support">Support</Link></Menu.Item>
      <Menu.Item key="about"><Link to="/about">About</Link></Menu.Item>
      <Divider />
      <Menu.Item key="getStarted" onClick={showLoginModal}>Get Started</Menu.Item>
    </Menu>
  );

  return (
    <>
{showPSBanner && (
  <Alert
    message={
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <span>Need a human review of your manuscripts?</span>
      </div>
    }
    description={
      <div>
        ScribeShadow has partnered with Problem Solved translation services.
        <br />
        <a href="http://translation.problemsolved.es/" target="_blank" rel="noopener noreferrer">Click here to learn more</a>,
        or email <a href="mailto:max@problemsolved.es">Max@problemsolved.es</a> for special rates for ScribeShadow customers.
      </div>
    }
    type="info"
    showIcon={false}
    closable
    onClose={handleClosePSBanner}
    style={{ 
      marginBottom: '20px',
      textAlign: 'center'
    }}
  />
)}
{showSpecialOfferBanner && (
  <Alert
    message="Special Offer"
    description={
      <div style={{ textAlign: 'center' }}>
        Use the coupon code <Text strong>SCRIBE20</Text> for 
        20% off your first month <br/><Link to="/account">Subscribe Today!</Link>
      </div>
    }
    type="info"
    showIcon={false}
    closable
    style={{ marginBottom: '20px', textAlign: 'center' }} // Adjust style as needed
  />
)}
    <Header style={{ 
      padding: '0 20px', 
      background: '#ffffff', 
      minHeight: '70px', 
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center',
    }}>
      <div style={{ 
        display: 'flex', 
        justifyContent: 'flex-start', 
        alignItems: 'center',
        flex: screens.md ? 1 : 'none',
      }}>
        <img src="/poelogo.png" alt="logo" style={{ height: "75px" }}/>
      </div>
      {screens.md && (
        <Menu mode="horizontal" style={{ 
          borderBottom: 'none',
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
        }}>

		{/*
		 {isBetaUser ? (
			<Menu.Item key="create">
			  <StyledLink to="/create">Create</StyledLink>				  
            </Menu.Item>
	
          ) : (
            <Tooltip title="This feature is available for beta users only">
              <Menu.Item key="create" disabled>
                <span style={{ cursor: 'not-allowed' }}>Create</span>
              </Menu.Item>
            </Tooltip>
		)} 
		*/}
		{/*
            <Tooltip title="This feature is temorary unavailable while we work on improvements.">
              <Menu.Item key="create" disabled>
                <span style={{ cursor: 'not-allowed' }}>Create</span>
              </Menu.Item>
            </Tooltip>		
		*/}
		
		  <Item key="manageBooks"><StyledLink to="/manage-books">My Books</StyledLink></Item>
	  
		  {user ? (
		 	 <Item key="pricing"><StyledLink to="/account">Account</StyledLink></Item>
		  ) : (
		 	 <Item key="pricing"><StyledLink to="/pricing">Pricing</StyledLink></Item>
		  )}
		  <Item key="support"><StyledLink to="/support">Support</StyledLink></Item>
		  <Item key="about"><StyledLink to="/about">About</StyledLink></Item>
        </Menu>
      )}
      <div style={{ 
        display: 'flex', 
        justifyContent: 'flex-end', 
        alignItems: 'center',
        flex: screens.md ? 1 : 'none',
      }}>
        {screens.md && user ? (
          <Dropdown overlay={userMenu} placement="bottomRight">
              <Avatar style={{ cursor: 'pointer' }}>{(displayName.charAt(0)).toUpperCase()}</Avatar>
          </Dropdown>
        ) : screens.md && (
          <Button onClick={showLoginModal}>Get Started</Button>
        )}
      </div>
        {!screens.md && (
          user ? (
            <Dropdown overlay={mobileMenu} trigger={['click']}>
              <Button icon={<UserOutlined />} />
            </Dropdown>
          ) : (
            <Dropdown overlay={mobileGuestMenu} trigger={['click']}>
              <Button icon={<UserOutlined />} />
            </Dropdown>
          )
        )}
    </Header>
	 </>
  );
};

export default HeaderComponent;
